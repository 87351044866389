import { Injectable } from '@angular/core';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { _isUndefined } from '@core/lodash/lodash';
import { PracticeLocation, SecurityManagerService } from '@core/security-manager/security-manager.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { OptionItemResponse, OptionItemService } from '@core/option-item/option-item.service';
import { AuthenticationService } from '@core/authentication/authentication.service';

@Injectable({
	providedIn: 'root',
})
export class UserLocationsService {

	currentLocation: BehaviorSubject<PracticeLocation> = new BehaviorSubject(undefined);

	constructor(
		private securityManager: SecurityManagerService,
		private eventsManager: EventsManagerService,
		private authenticationService: AuthenticationService,
	) {
		this.eventsManager.subscribe(HIT_PMS_HTML_EVENTS.LOCATION.LOCATION_CHANGE, () => this.updateUserLocation(), this);
	}

	observeCurrentUserLocation(): Observable<PracticeLocation> {
		return this.currentLocation.asObservable().pipe(
			filter((value) => !_isUndefined(value)),
		);
	}

	getCurrentUserLocation(): PracticeLocation {
		return this.securityManager.getUserLocationById(this.securityManager.createSecurityContext().locationId);
	}

	updateUserLocation(): void {
		const updatedLocation = this.getCurrentUserLocation();
		// wait for Rev360 backend session to get updated before notifying any observers.
		this.authenticationService.updateCurrentLocation(updatedLocation.id).subscribe(() => this.currentLocation.next(updatedLocation));
	}

	getUserLocations(): OptionItemResponse<PracticeLocation, number>[] {
		return OptionItemService.toOptionItems(this.securityManager.getUserLocations(), item => item.id, item => item.name);
	}

	getUserActiveLocations(): OptionItemResponse<PracticeLocation, number>[] {
		return OptionItemService.toOptionItems(this.securityManager.getUserActiveLocations(), item => item.id, item => item.name);
	}

	getAllActiveLocations(): OptionItemResponse<PracticeLocation, number>[] {
		return OptionItemService.toOptionItems(this.securityManager.getActivePracticeLocations(), item => item.id, item => item.name);

	}
}

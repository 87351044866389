import { LoadingOverlayMethod, ModalBase , GridUtil } from 'morgana';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DialogComponent } from '@syncfusion/ej2-angular-popups';
import { ColDef, RowClickedEvent } from 'ag-grid-community';
import { AgGridAngular } from 'ag-grid-angular';
import { SmartApplicationService } from '@shared/component/encounter-launch-smart-application-modal/smart-application.service';
import { PracticeSmartApplicationResponse } from '@gandalf/model/practice-smart-application-response';
import { ProviderResponse } from '@gandalf/model/provider-response';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { combineLatest, Observable } from 'rxjs';
import { _isEmpty } from '@core/lodash/lodash';
import { GetPracticeLaunchUrlRequest } from '@gandalf/model/get-practice-launch-url-request';
import { PracticeLaunchUrlResponse } from '@gandalf/model/practice-launch-url-response';

export interface LaunchSmartApplicationData {
	patientId: number;
}

@Component({
	selector: 'pms-encounter-launch-smart-application-modal',
	templateUrl: './launch-smart-application-modal.component.html',
})
export class LaunchSmartApplicationModalComponent extends ModalBase<LaunchSmartApplicationData> implements OnInit {

	@ViewChild('modal')
	modal: DialogComponent;

	@ViewChild('agGrid')
	agGrid: AgGridAngular;

	gridColumns: ColDef[];
	launchSmartApplicationGridOptions = GridUtil.buildGridOptions({
		suppressRowClickSelection: true,
		rowClass: 'row-link',
	});
	providerNpi: string;
	_isLoading: boolean;
	practiceSmartApplicationResponses: PracticeSmartApplicationResponse[] = [];

	constructor(
		private smartApplicationService: SmartApplicationService,
		private securityManagerService: SecurityManagerService,
	) {
		super();
	}

	ngOnInit() {
		if(this.securityManagerService.userIsProvider()) {
			combineLatest([
				this.smartApplicationService.findProviderDetails(),
				this.smartApplicationService.findEnabledSmartApplications(),
			])
				.subscribe(([provider, practiceSmartApplications]) => {
					this.setSmartApplications(provider, practiceSmartApplications);
				});
		}
	}

	setSmartApplications(provider: ProviderResponse, practiceSmartApplications: PracticeSmartApplicationResponse[]) {
		if(!_isEmpty(provider.npi)) {
			this.providerNpi = provider.npi;
			this.practiceSmartApplicationResponses = practiceSmartApplications;
			this.smartApplicationService.updateOrCreatePatientCcdaExport(this.data.patientId).subscribe();
		}
	}

	onGridReady() {
		this.buildGridColumns();
		this.agGrid.api.setColumnDefs(this.gridColumns);
	}

	launchSmartApplication(event: RowClickedEvent) {
		this.getPracticeLaunchUrl(event.data.masterSmartApplicationId).subscribe(practiceLaunchUrlResponse => {
			this.smartApplicationService.launchSmartApplicationWindow(practiceLaunchUrlResponse.practiceLaunchUrl);
			this.closeModal();
		});
	}

	@LoadingOverlayMethod()
	getPracticeLaunchUrl(masterSmartApplicationId): Observable<PracticeLaunchUrlResponse> {
		const request = new GetPracticeLaunchUrlRequest();
		request.masterSmartApplicationId = masterSmartApplicationId;
		request.providerNpi = this.providerNpi;
		return this.smartApplicationService.getPracticeLaunchUrl(request);
	}

	buildGridColumns() {
		this.gridColumns = [
			GridUtil.buildColumn('Name', 'masterSmartApplicationName', {
				flex: 1,
				sortable: false,
			}),
		];
	}

	closeModal() {
		this.dynamicModalRef.close(this.modal);
	}
}

<ng-container *ngFor="let iframe of iframeContainerService.iframes">
	<rev-loading-overlay *ngIf="iframe.isEnabled" [hidden]="!iframe.isActive" [loading]="!iframe.isLoaded">
		<iframe
			*ngIf="iframe.url"
			[hidden]="!iframe.isLoaded"
			[src]="iframe.url"
			(load)="iframe.loaded($any($event.target))"
			[height]="iframeHeight"
			style="width: 100%; border: 0"
			referrerpolicy="no-referrer"></iframe>
	</rev-loading-overlay>
</ng-container>

import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { INTER_APP_CONSTANTS } from '@core/legacy/inter-app.constants';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { NavigationService } from '@core/navigation/navigation.service';
import { SECURITY_CONSTANTS } from '@core/security/security.constants';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { ToasterService } from '@core/toaster/toaster.service';
import { PreferenceDefaults, PreferenceName } from '@gandalf/constants';
import { AskQuestionRequest } from '@gandalf/model/ask-question-request';
import { FindTodaysPatientsAppointmentsForProviderRequest } from '@gandalf/model/find-todays-patients-appointments-for-provider-request';
import { SendFeedbackRequest } from '@gandalf/model/send-feedback-request';
import { StartAppointmentRequest } from '@gandalf/model/start-appointment-request';
import { EmployeeTaskGandalfService, HeaderGandalfService } from '@gandalf/services';
import { ONE_MINUTE } from '@shared/constants/time.constants';
import { SortingService } from 'morgana';
import { filter, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { UrlService } from '@core/url-util/url.service';

@Injectable({
	providedIn: 'root',
})
export class NavigationMenuService {

	private unreadConversationsCount = new BehaviorSubject<number>(0);
	private unreadConversationsCountIntervalSubscription: Subscription;

	constructor(
		private headerGandalfService: HeaderGandalfService,
		private employeeTaskGandalfService: EmployeeTaskGandalfService,
		private eventsManagerService: EventsManagerService,
		private featureService: FeatureService,
		private securityManagerService: SecurityManagerService,
		private toasterService: ToasterService,
		private navigationService: NavigationService,
		private router: Router,
		public securityManager: SecurityManagerService,
		private urlService: UrlService,
	) {
	}

	/* istanbul ignore next: gandalf */
	getNewTaskCountForEmployee() {
		return this.employeeTaskGandalfService.getActiveTaskCountForEmployee();
	}

	/* istanbul ignore next: gandalf */
	getNewMessagesCountForEmployee() {
		return this.headerGandalfService.getNewMessageParticipantCountForEmployee();
	}

	/* istanbul ignore next: gandalf */
	askRevAssureQuestion(request: AskQuestionRequest) {
		return this.headerGandalfService.askRevAssureQuestion(request);
	}

	/* istanbul ignore next: gandalf */
	askMeaningfulUseQuestion(request: AskQuestionRequest) {
		return this.headerGandalfService.askMeaningfulUseQuestion(request);
	}

	/* istanbul ignore next: gandalf */
	sendFeedback(request: SendFeedbackRequest) {
		return this.headerGandalfService.sendFeedback(request);
	}

	findTodaysPatientsAppointments(locationId: number) {
		return this.headerGandalfService.findTodaysPatientsAppointments(locationId).pipe(
			map(appointments => SortingService.sortBy(appointments, ['startDate', 'appointmentId'], ['asc', 'asc'])),
		);
	}

	findTodaysPatientsAppointmentsForProvider(request: FindTodaysPatientsAppointmentsForProviderRequest) {
		return this.headerGandalfService.findTodaysPatientsAppointmentsForProvider(request).pipe(
			map(appointments => SortingService.sortBy(appointments, ['startDate', 'appointmentId'], ['asc', 'asc'])),
		);
	}

	startAppointment(request: StartAppointmentRequest) {
		return this.headerGandalfService.startEncounterByAppointment(request);
	}

	observeUnreadConversationsCount(): Observable<number> {
		return this.unreadConversationsCount;
	}

	refreshUnreadConversationsCount() {
		this.headerGandalfService.getUnreadConversationsCount().subscribe((count) => {
			this.unreadConversationsCount.next(count);
		});
	}

	handleUnreadConversationCount() {
		if (!this.hasTwoWayTextNotificationEnabled()) {
			return;
		}

		this.headerGandalfService.getUnreadConversationsCount().subscribe((count) => {
			this.unreadConversationsCount.next(count);
			this.displayUnreadConversationToaster(count);
		});
	}

	displayUnreadConversationToaster(count: number) {
		if (count === 0) {
			return;
		}

		this.toasterService.showDefaultNotificationToast({
			title: `You have ${count} Unread RevConnect Two-Way Texting Conversations`,
			buttons: [
				{
					model: {
						content: 'More Info',
						cssClass: 'e-link',
					},
					click: () => this.navigationService.navigateToTwoWayText(),
				},
			],
		});

		this.router.events.pipe(filter(
			event => event instanceof NavigationStart))
			.subscribe((event: NavigationStart) => {
				if (event.url.indexOf('home') === -1) {
					this.clearToasts();
				}
			});
	}

	incrementUnreadConversationsCount() {
		const newCount = this.unreadConversationsCount.value + 1;
		this.unreadConversationsCount.next(newCount);
	}

	decrementUnreadConversationsCount() {
		let newCount = this.unreadConversationsCount.value - 1;
		if (newCount < 0) {
			newCount = 0;
		}
		this.unreadConversationsCount.next(newCount);
	}

	subscribeToTwtUnreadConversationsInterval() {
		if (this.shouldSubscribeToUnreadConversation()) {
			// Notify legacy header of all count changes
			this.unreadConversationsCount.subscribe((newCount) => {
				this.eventsManagerService.publish(INTER_APP_CONSTANTS.REV360.CONNECT.TWO_WAY_TEXTING_UNREAD_CONVERSATIONS_COUNT, newCount);
			});
			this.unreadConversationsCountIntervalSubscription = this.subscribeToUnreadConversationsInterval();
			this.eventsManagerService.subscribe(HIT_PMS_HTML_EVENTS.SECURITY.SECURITY_SESSION_LOCKED, () => {
				this.unreadConversationsCountIntervalSubscription.unsubscribe();
			});
			this.eventsManagerService.subscribe(HIT_PMS_HTML_EVENTS.SECURITY.SECURITY_SESSION_UNLOCKED, () => {
				this.unreadConversationsCountIntervalSubscription = this.subscribeToUnreadConversationsInterval(true);
			});

			this.handleUnreadConversationCount();
		}
	}

	subscribeToUnreadConversationsInterval(isUnlocking: boolean = false) {
		const startDelay = (this.hasTwoWayTextNotificationEnabled() && !isUnlocking) ? ONE_MINUTE : 0;
		return timer(startDelay, ONE_MINUTE).subscribe(() => {
			this.refreshUnreadConversationsCount();
		});
	}

	hasTwoWayTextNotificationEnabled() {
		return this.securityManagerService.preferenceValueIsOn(
			PreferenceName.TWO_WAY_TEXT_NOTIFICATIONS.value,
			PreferenceDefaults.TWO_WAY_TEXT_NOTIFICATIONS.value,
		);
	}

	clearToasts() {
		this.toasterService.hideDefaultNotificationToast();
	}

	shouldSubscribeToUnreadConversation() {
		return this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.REVCONNECT.TWO_WAY_TEXT)
			&& this.securityManagerService.allowTwoWayTexting()
			&& this.securityManager.hasPermission(SECURITY_CONSTANTS.RESOURCE_CONNECT_VIEW)
			&& this.securityManager.hasPermission(SECURITY_CONSTANTS.RESOURCE_CONNECT_VIEW_TWO_WAY_TEXT);
	}

	subscribeToLegacyRevEngage() {
		this.eventsManagerService.subscribeObservable(INTER_APP_CONSTANTS.REV360.REVENGAGE.OPEN_TAB).subscribe(() => this.openDoctibleSsoTab());
	}

	openDoctibleSsoTab() {
		return this.headerGandalfService.getDoctibleSsoUrl().subscribe(url => this.urlService.goToWebpage(url.url, 'doctible'));
	}
}

import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FileService } from '@core/file/file.service';
import { _isNil } from '@core/lodash/lodash';
import { DialogUtil, ModalManagerService, TypeSafeModalManagerService } from 'morgana';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { PatientFileEntityType, PreferenceName } from '@gandalf/constants';
import { PatientFileListResponse } from '@gandalf/model/patient-file-list-response';
import { SearchPatientFilesRequest } from '@gandalf/model/search-patient-files-request';
import { FileScanModalComponent } from '@shared/component/file-scan-modal/file-scan-modal.component';
import { FileUploadModalComponent } from '@shared/component/file-upload-modal/file-upload-modal.component';
import { PatientDocumentsService } from '@shared/component/patient-documents/patient-documents.service';
import { TABLE_DATE_FORMATS } from '@shared/constants/date-format.constants';
import { Dialog } from '@syncfusion/ej2-angular-popups';
import { featureToken } from '@core/injection-tokens/feature-flag-tokens/feature-flag-tokens';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { PatientFolderModalComponent } from '@core/folder-modal/patient-folder-modal.component';
import { DrawingToolModalComponent } from '../../../drawing-tool/drawing-tool-modal/drawing-tool-modal.component';

@Component({
	selector: 'pms-patient-documents',
	templateUrl: './patient-documents.component.html',
	styles: [],
	providers: [TypeSafeModalManagerService, ModalManagerService],
})
export class PatientDocumentsComponent implements OnInit {

	readonly emrDrawingToolFeatureOn = inject(featureToken(FEATURE_FLAGS.FEATURES.ENCOUNTERS.EMR_DRAWING_TOOL));

	files: PatientFileListResponse[] = [];
	canScan = false;
	@Input()
	patientId: number;

	@Input()
	parentId: number;

	@Input()
	disabled = false;

	@Input()
	entityType: PatientFileEntityType;

	@Input()
	encounterId: number;

	@Output()
	update: EventEmitter<boolean> = new EventEmitter();

	@Output()
	fileCountUpdated: EventEmitter<number> = new EventEmitter<number>();

	dayFormatter = TABLE_DATE_FORMATS.MM_DD_YYYY;
	confirmDialog: Dialog;

	constructor(
		private securityManagerService: SecurityManagerService,
		public modalManagerService: ModalManagerService,
		public typeSafeModalManagerService: TypeSafeModalManagerService,
		private patientFileService: PatientDocumentsService,
		private fileService: FileService,
	) {
	}

	get fileCount() {
		return !_isNil(this.files) ? this.files.length : 0;
	}

	ngOnInit() {
		this.canScan = this.securityManagerService.preferenceValueIsOn(PreferenceName.SCANNING_ENABLED.value, 'true');
		this.getPatientFilesList();
	}

	getPatientFilesList() {
		if (this.parentId) {
			const req = new SearchPatientFilesRequest();
			req.patientId = this.patientId;
			req.parentId = this.parentId;
			req.entityType = this.entityType;
			this.patientFileService.getPatientFiles(req).subscribe(files => {
				this.files = files;
				this.fileCountUpdated.emit(this.fileCount);
			});
		}
	}

	/*istanbul ignore next */
	openUploadModal() {
		this.modalManagerService.open(FileUploadModalComponent, {
			data: {
				parentId: this.parentId,
				patientId: this.patientId,
				entityType: this.entityType,
				encounterId: this.encounterId,
			},
		}).onClose.subscribe(
			(refresh) => {
				this.onModalClose(refresh);
			},
		);
	}

	openScanModal() {
		this.modalManagerService.open(FileScanModalComponent, {
			data: {
				parentId: this.parentId,
				patientId: this.patientId,
				entityType: this.entityType,
				encounterId: this.encounterId,
			},
		}).onClose.subscribe(
			(refresh) => {
				this.onModalClose(refresh);
			},
		);
	}

	shouldShowDrawButton() {
		return this.emrDrawingToolFeatureOn && !_isNil(this.encounterId);
	}

	openPatientFolderModalForDrawingTool() {
		return this.typeSafeModalManagerService.open(PatientFolderModalComponent, {
			submitText: 'Draw',
		}).onClose.subscribe((selectedFolderId) => {
			if (_isNil(selectedFolderId)) {
				return;
			}
			this.draw(selectedFolderId);
		});
	}

	draw(selectedFolderId: string) {
		this.modalManagerService.open(DrawingToolModalComponent, {
			data: {
				folderId: selectedFolderId,
				patientId: this.patientId,
				fileData: null,
				parentId: this.parentId,
				entityType: this.entityType,
				encounterId: this.encounterId,
			},
		}).onClose.subscribe((refresh) => {
			this.onModalClose(refresh);
		});
	}

	isImageEditable(data: PatientFileListResponse) {
		return this.emrDrawingToolFeatureOn && FileService.mimeTypeIsImage(data.mimeType) && !_isNil(this.encounterId);
	}

	editImage(data: PatientFileListResponse) {
		this.modalManagerService.open(DrawingToolModalComponent, {
			data: {
				folderId: data.folderId,
				patientId: this.patientId,
				fileData: data,
				parentId: null,
				entityType: null,
				encounterId: null,
			},
		}).onClose.subscribe(refresh => {
			this.onModalClose(refresh);
		});
	}

	onModalClose(refresh: boolean) {
		if (refresh) {
			this.update.emit(refresh);
			this.getPatientFilesList();
		}
	}

	downloadImage(data: PatientFileListResponse) {
		this.fileService.downloadPatientFile(this.patientId, data.patientFileId);
	}

	isPreviewable(data: PatientFileListResponse) {
		return this.fileService.isMimeTypePreviewable(data.mimeType);
	}

	openPreview(data: PatientFileListResponse) {
		this.fileService.viewOrDownloadPatientFile(this.patientId, data);
	}

	/*istanbul ignore next */
	showDeleteModal(data) {
		const patientFileId = data.patientFileId;
		this.confirmDialog = DialogUtil.confirm({
			title: 'Delete File',
			content: 'Are you sure you want to delete this file?',
			okButton: {
				click: this.deleteItemCloseModal.bind(this, patientFileId),
			},
		});
	}

	deleteItemCloseModal(patientFileId) {
		this.confirmDialog.close();
		this.patientFileService.deleteFile(patientFileId).subscribe(() => {
			this.getPatientFilesList();
			this.update.emit(true);
		});
	}
}

// This is the number of pixels for the wrapper margin (50), sub header (38), search bar (51), panel body (20), application footer (47),
// and (0) for bottom panel margin (adding 0 bottom class)
// Calculated as 50 + 38 + 51 + 20 + 47 + 0 = 206 rounded down because we like round numbers
export const PANEL_HEIGHT_ADJUSTMENT = 205;

/**
 * Number of pixels for the header and footer height. Intended to be subtracted from the window height to get the total available height for a component without
 * scrolling.
 */
export const IFRAME_HEIGHT_ADJUSTMENT = 102;

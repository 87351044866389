import { AccountingService } from '@accounting/core/accounting/accounting.service';
import { InvoiceService } from '@accounting/core/accounting/invoice-service/invoice.service';
import { PaymentService } from '@accounting/core/accounting/payment-service/payment.service';
import { EditPaymentModalComponent, EditPaymentModalData } from '@accounting/invoices/edit-payment-modal/edit-payment-modal.component';
import { ManualProcessPaymentModalComponent } from '@accounting/invoices/manual-process-payment-modal/manual-process-payment-modal.component';
import {
	OpenEdgeManualCardModalComponent,
	OpenEdgeManualCardModalResult,
	OpenEdgePaymentSaveResult
} from '@accounting/invoices/open-edge-manual-card-modal/open-edge-manual-card-modal.component';
import { OnFileModalMode, OpenEdgeOnFileCardModalComponent } from '@accounting/invoices/open-edge-on-file-card-modal/open-edge-on-file-card-modal.component';
import { CardReaderMode, OpenEdgeReadCardModalComponent } from '@accounting/invoices/open-edge-read-card-modal/open-edge-read-card-modal.component';
import { CreditCardInput } from '@accounting/invoices/receive-payments/receive-payments/receive-payments.component';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PaymentComponentService, PaymentGroupRequest } from '@core/accounting/payment/payment-component.service';
import { EnumUtil, LoadingOverlayMethod, ModalManagerService, TypeSafeModalManagerService } from 'morgana';
import { EventsManagerService } from '@core/events-manager/events-manager.service';
import { FEATURE_FLAGS } from '@core/feature/feature.constants';
import { FeatureService } from '@core/feature/feature.service';
import { HIT_PMS_HTML_EVENTS } from '@core/legacy/hit-pms-html.constants';
import { _isEmpty, _isNil } from '@core/lodash/lodash';
import { OpenEdgePaymentService } from '@core/open-edge-payment/open-edge-payment.service';
import { SECURITY_CONSTANTS } from '@core/security/security.constants';
import { SecurityManagerService } from '@core/security-manager/security-manager.service';
import { ToasterService } from '@core/toaster/toaster.service';
import { InvoicesUpdatedResponse } from '@gandalf/model/invoices-updated-response';
import { ManualProcessPaymentRequest } from '@gandalf/model/manual-process-payment-request';
import { UpdatePaymentGroupRequest } from '@gandalf/model/update-payment-group-request';
import { CreditCardType, PayerType, PaymentGroupStatus, PaymentTransactionProcessor } from '@gandalf/constants';
import { OpenEdgeTransactionDetailsResponse } from '@gandalf/model/open-edge-transaction-details-response';
import { BaseComponent } from '@shared/component/base.component';
import { PrintUtilService } from '@shared/Utils/print-util.service';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { URL_ACCOUNTING_ENDPOINTS } from '@shared/constants/url.constants';
import { UrlService } from '@core/url-util/url.service';

export const PERFORMANCE_METHODS_THRESHOLD = 20;

@Component({
	selector: 'pms-receive-payments-actions',
	templateUrl: './receive-payments-actions.component.html',
	providers: [TypeSafeModalManagerService],
	styles: [],
})
export class ReceivePaymentsActionsComponent extends BaseComponent implements OnInit, OnDestroy {

	@Input()
	paymentGroupId: number;

	blueFinCallback: (event: any) => void;
	_isLoading: boolean;
	editPaymentFeatureFlag: boolean;
	userCanEditPayment: boolean;
	hasPaymentProcessor: boolean;
	printReceiptsFeatureFlag: boolean;
	paymentPerformanceFeatureFlag = false;

	constructor(
		private accountingService: AccountingService,
		private openEdgePaymentService: OpenEdgePaymentService,
		private modalManagerService: ModalManagerService,
		private printUtilService: PrintUtilService,
		private toasterService: ToasterService,
		private eventsManagerService: EventsManagerService,
		private invoiceService: InvoiceService,
		private paymentService: PaymentService,
		private featureService: FeatureService,
		private typeSafeModalManagerService: TypeSafeModalManagerService,
		private urlService: UrlService,
		public securityManagerService: SecurityManagerService,
		public paymentComponentService: PaymentComponentService,
	) {
		super();
	}

	ngOnInit() {
		this.editPaymentFeatureFlag = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ACCOUNTING.PAYMENTS.EDIT_APPLIED_PAYMENT);
		this.userCanEditPayment = this.securityManagerService.hasPermission(SECURITY_CONSTANTS.RESOURCE_ACCOUNTING_PAYMENT_EDIT);
		this.printReceiptsFeatureFlag = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ACCOUNTING.PAYMENTS.PRINT_RECEIPTS);
		this.paymentPerformanceFeatureFlag = this.featureService.isFeatureOn(FEATURE_FLAGS.FEATURES.ACCOUNTING.PAYMENTS.PERFORMANCE);
		this.setHasPaymentProcessor(this.paymentGroupId);
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
		// if we never got a result then it must have errored so we need to remove this when closing the modal
		this.unsubscribeBlueFinCallback();
	}

	showEditPayment() {
		return this.editPaymentFeatureFlag
			&& this.userCanEditPayment
			&& this.isPaymentStatusApplied()
			&& !this.hasPaymentProcessor;
	}

	isPaymentStatusApplied() {
		return EnumUtil.equals(this.paymentComponentService.paymentGroup?.status, PaymentGroupStatus.APPROVED) ?? false;
	}

	isPaymentStatusPending() {
		return EnumUtil.equals(this.paymentComponentService.paymentGroup?.status, PaymentGroupStatus.PENDING) ?? false;
	}

	payerIsPatientOrGuest() {
		return (EnumUtil.equalsOneOf(this.paymentComponentService.payer?.type, PayerType.PATIENT, PayerType.ANONYMOUS)
			|| EnumUtil.equalsOneOf(this.paymentComponentService.paymentGroup?.payerType, PayerType.PATIENT, PayerType.ANONYMOUS)) ?? false;
	}

	payerIsInsurance() {
		return (this.paymentComponentService.payer?.type === PayerType.INSURANCE
			|| this.paymentComponentService.paymentGroup?.payerType === PayerType.INSURANCE) ?? false;
	}

	showPrintReceipts() {
		return this.printReceiptsFeatureFlag
			&& !this.showProcessPayment()
			&& this.payerIsPatientOrGuest()
			&& (_isNil(this.paymentComponentService.paymentGroup) || this.isPaymentStatusPending());
	}

	showProcessPaymentAndPrintReceipts() {
		return this.printReceiptsFeatureFlag
			&& this.showProcessPayment()
			&& this.payerIsPatientOrGuest()
			&& (_isNil(this.paymentComponentService.paymentGroup) || this.isPaymentStatusPending());
	}

	showApplyPaymentWithoutProcessing() {
		return !!(this.showProcessPayment() && this.paymentComponentService.isOpenEdgeActive()
			&& !_isNil(this.paymentComponentService.formGroup?.errors) && this.paymentComponentService.formGroup?.errors['creditCardPaymentNegative']);
	}

	disableApplyPaymentsWithoutProcessing() {
		return this._isLoading || Object.keys(this.paymentComponentService.formGroup?.errors ?? {}).length > 1;
	}

	showProcessPayment() {
		return this.paymentComponentService.isCreditCardPaymentMethod() && this.paymentComponentService.showCardProcessing();
	}

	saveOrApplyPayment(event: MouseEvent, applyingPayment: boolean, printReceipt: boolean) {
		const request = this.paymentComponentService.buildValidPaymentGroupRequest(event, applyingPayment);
		if (_isNil(request)) {
			return;
		}

		if (applyingPayment) {
			this.validateInvoiceDirectPayment(request, () => this.saveOrApplyPaymentGroupRequest(true, request, printReceipt));
		} else {
			this.saveOrApplyPaymentGroupRequest(false, request, printReceipt);
		}
	}

	saveOrApplyPaymentGroupRequest(
		applyingPayment: boolean,
		request: PaymentGroupRequest,
		printReceipt: boolean,
		customerReceipt?: string,
		savedCardResult?: OpenEdgePaymentSaveResult,
	) {
		this.getPaymentGroupAction(applyingPayment, request).subscribe(result => this.handlePaymentGroupResults(printReceipt, result, customerReceipt, savedCardResult));
	}

	@LoadingOverlayMethod()
	getPaymentGroupAction(applyingPayment: boolean, request: PaymentGroupRequest): Observable<any> {
		const numberOfPayments = request.payments?.length ?? 0;
		if (_isNil(this.paymentComponentService.paymentGroup)) {
			return applyingPayment
				? this.usePerformanceMethods(numberOfPayments)
					? this.accountingService.applyInsurancePayments(request)
					: this.accountingService.applyPayments(request)
				: this.accountingService.savePayments(request);
		} else {
			const updateRequest = request as UpdatePaymentGroupRequest;
			return applyingPayment
				? this.usePerformanceMethods(numberOfPayments)
					? this.accountingService.applyExistingInsurancePayments(updateRequest)
					: this.accountingService.applyExistingPayments(updateRequest)
				: this.accountingService.saveExistingPayments(updateRequest);
		}
	}

	usePerformanceMethods(numberOfPayments: number): boolean {
		return this.paymentPerformanceFeatureFlag && this.payerIsInsurance() && numberOfPayments > PERFORMANCE_METHODS_THRESHOLD;
	}

	printInvoices(invoicesUpdatedResponses?: InvoicesUpdatedResponse[]) {
		if (_isEmpty(invoicesUpdatedResponses)) {
			return;
		}

		const invoiceIdsAsString = invoicesUpdatedResponses
			.filter(response => EnumUtil.equalsOneOf(response.payerType, PayerType.PATIENT, PayerType.ANONYMOUS))
			.map(filteredResponse => filteredResponse.invoiceId)
			.join(',');

		this.urlService.openTabWithPost(URL_ACCOUNTING_ENDPOINTS.PRINT_INVOICE, {invoiceId: invoiceIdsAsString});
	}

	handlePaymentGroupResults(
		printReceipt: boolean,
		invoiceUpdateResponse?: any,
		customerReceipt?: string,
		savedCardResult?: OpenEdgePaymentSaveResult,
	) {
		invoiceUpdateResponse?.forEach(response => this.invoiceService.refreshInvoice(response.invoiceId));
		this.paymentService.refreshPayments();
		this.paymentComponentService.closePayment();

		if (!_isNil(printReceipt) && printReceipt) {
			this.printInvoices(invoiceUpdateResponse);
		}

		if (savedCardResult === OpenEdgePaymentSaveResult.SAVED) {
			this.toasterService.showSavedSuccess({content: 'Card successfully saved', newestOnTop: false});
		} else if (savedCardResult === OpenEdgePaymentSaveResult.NOT_SAVED) {
			this.toasterService.showError({content: 'Card not saved'});
		}

		if (customerReceipt) {
			setTimeout(() => {
				this.printUtilService.printFormattedText(customerReceipt);
			}, 2000);
		}
	}

	applyPaymentWithoutProcessing(event: MouseEvent) {
		const modalRequest = this.paymentComponentService.applyPaymentWithoutProcessing(event);
		if (!_isNil(modalRequest)) {
			this.validateInvoiceDirectPayment(modalRequest, () => this.openManualProcessPaymentModal(modalRequest, false));
		}
	}

	processPayment(event: MouseEvent, printReceipt: boolean) {
		const request = this.paymentComponentService.buildValidPaymentGroupRequest(event, true);
		if (_isNil(request)) {
			return;
		}
		this.validateInvoiceDirectPayment(request, () => {
			if (this.paymentComponentService.isOpenEdgeActive()) {
				switch (this.paymentComponentService.getSelectedCreditCardInput()) {
					case CreditCardInput.READER:
						this.readCardOpenEdge(request, printReceipt);
						break;
					case CreditCardInput.MANUAL:
						this.manualCardOpenEdge(request, printReceipt);
						break;
					case CreditCardInput.ON_FILE:
						this.onFileCardOpenEdge(request, printReceipt);
						break;
				}
			} else if (this.paymentComponentService.isBlueFinActive()) {
				switch (this.paymentComponentService.getSelectedCreditCardInput()) {
					case CreditCardInput.READER:
						this.readCardBlueFin(request, printReceipt);
						break;
					case CreditCardInput.MANUAL:
						this.openManualProcessPaymentModal(request, printReceipt);
						break;
				}
			}
		});
	}

	validateInvoiceDirectPayment(request: PaymentGroupRequest, onSuccess: () => any) {
		this.paymentComponentService.errors = [];
		if (!request.applyFull) {
			onSuccess();
			return;
		}
		const invoiceIds = request.payments.map(payment => payment.invoiceId);
		this.checkIfInvoiceHasDirectPayment(invoiceIds).subscribe(data => {
			if (data.invoiceIds?.length) {
				this.paymentComponentService.errors = [`Invoice items do not balance with payment-in-full amount: #${data.invoiceIds.join(', #')}`];
			} else {
				onSuccess();
			}
		});
	}

	@LoadingOverlayMethod()
	checkIfInvoiceHasDirectPayment(invoiceIds: number[]) {
		return this.accountingService.checkIfInvoiceHasDirectPayment(invoiceIds);
	}

	openManualProcessPaymentModal(request: PaymentGroupRequest, printReceipt: boolean) {
		this.modalManagerService.open(ManualProcessPaymentModalComponent, {
			data: {
				totalAmount: request.paymentAmount,
				creditCardTypes: this.paymentComponentService.creditCardTypes,
				referenceNumber: request.referenceNumber,
			},
		}).onClose.subscribe(manualRequest => this.handleManualProcessPayment(request, manualRequest, printReceipt));
	}

	handleManualProcessPayment(request: PaymentGroupRequest, manualRequest: ManualProcessPaymentRequest, printReceipt: boolean) {
		if (!_isNil(manualRequest)) {
			request.creditCardType = manualRequest.creditCardType;
			request.referenceNumber = manualRequest.referenceNumber;
			this.saveOrApplyPaymentGroupRequest(true, request, printReceipt);
		}
	}

	readCardBlueFin(request: PaymentGroupRequest, printReceipt: boolean) {
		// The event is NOT called when there's a reader error and the modal closes, so this unsubs from it if trying to read a card again
		this.unsubscribeBlueFinCallback();
		const creditCardPayment = {
			locationId: request.paymentLocationId,
			amount: request.paymentAmount,
			transactionType: 'SALE',
			correlationId: this.getCorrelationId(),
		};
		this.blueFinCallback = (event) => this.handleBlueFinTransaction(request, event.argument, creditCardPayment.correlationId, printReceipt);

		this.eventsManagerService.subscribe(HIT_PMS_HTML_EVENTS.ACCOUNTING.ACCOUNTING_PAYMENT_MADE, this.blueFinCallback);
		this.eventsManagerService.publish(HIT_PMS_HTML_EVENTS.ACCOUNTING.ACCOUNTING_RECEIVE_PAYMENT, creditCardPayment);
	}

	unsubscribeBlueFinCallback() {
		if (this.blueFinCallback) {
			this.eventsManagerService.unsubscribe(HIT_PMS_HTML_EVENTS.ACCOUNTING.ACCOUNTING_PAYMENT_MADE, this.blueFinCallback);
			this.blueFinCallback = null;
		}
	}

	/* istanbul ignore next */
	getCorrelationId() {
		return uuidv4();
	}

	handleBlueFinTransaction(request: PaymentGroupRequest, transaction: any, correlationId: string, printReceipt: boolean) {
		if (transaction.correlationId !== correlationId) {
			return;
		}
		const cardType = EnumUtil.findEnumByValue(transaction.cardType, CreditCardType);
		this.applyPaymentsForCreditCardTransaction(request, transaction.transactionKey, transaction.cardLast4, cardType, printReceipt, transaction.paymentTransactionId);
	}

	readCardOpenEdge(request: PaymentGroupRequest, printReceipt: boolean) {
		const cardReader = this.paymentComponentService.getSelectedCardReader();
		this.openEdgePaymentService.setMostRecentlyUsedCardReader(cardReader.openEdgeCardReaderId);
		this.modalManagerService.open(OpenEdgeReadCardModalComponent, {
			data: {
				totalAmount: request.paymentAmount,
				reader: cardReader,
				cardReaderMode: CardReaderMode.PAYMENT,
				personId: this.getPatientPayerPersonId(request),
			},
		}).onClose.subscribe(result => this.handleOpenEdgeTransaction(request, result?.transaction, printReceipt, result?.storedPaymentResult));
	}

	onFileCardOpenEdge(request: PaymentGroupRequest, printReceipt: boolean) {
		this.modalManagerService.open(OpenEdgeOnFileCardModalComponent, {
			data: {
				paymentStoredTokenId: this.paymentComponentService.getSelectedOpenEdgeOnFileCard(),
				locationId: request.paymentLocationId,
				amount: request.paymentAmount,
				payerId: request.payerId,
				personId: this.getPatientPayerPersonId(request),
				transactionMode: OnFileModalMode.PAYMENT,
			},
		}).onClose.subscribe(transaction => this.handleOpenEdgeTransaction(request, transaction, printReceipt));
	}

	getPatientPayerPersonId(request: PaymentGroupRequest) {
		return this.paymentComponentService.isPatientPayer() ? request.payerEntityId : null;
	}

	manualCardOpenEdge(request: PaymentGroupRequest, printReceipt: boolean) {
		this.modalManagerService.open(OpenEdgeManualCardModalComponent, {
			data: {
				locationId: request.paymentLocationId,
				amount: request.paymentAmount,
				payerId: request.payerId,
				personId: this.getPatientPayerPersonId(request),
			},
		}).onClose.subscribe(result => this.handleOpenEdgeManualResult(request, result, printReceipt));
	}

	handleOpenEdgeManualResult(request: PaymentGroupRequest, result: OpenEdgeManualCardModalResult, printReceipt: boolean) {
		if (_isNil(result)) {
			return;
		}

		if (result.manualProcessRequested) {
			this.openManualProcessPaymentModal(request, printReceipt);
		} else if (!_isNil(result.transaction)) {
			this.handleOpenEdgeTransaction(request, result.transaction, printReceipt, result.paymentSaveResult);
		}
	}

	handleOpenEdgeTransaction(
		request: PaymentGroupRequest,
		transaction: OpenEdgeTransactionDetailsResponse,
		printReceipt: boolean,
		paymentSavedResult?: OpenEdgePaymentSaveResult,
	) {
		if (_isNil(transaction)) {
			return;
		}

		this.applyPaymentsForCreditCardTransaction(
			request,
			transaction.transactionId,
			transaction.cardLast4,
			transaction.cardType,
			printReceipt,
			transaction.paymentTransactionId,
			transaction.customerReceipt,
			paymentSavedResult,
		);
	}

	applyPaymentsForCreditCardTransaction(
		request: PaymentGroupRequest,
		transactionIdentifier: string,
		cardLast4: string,
		creditCardType: CreditCardType,
		printReceipt: boolean,
		paymentTransactionId?: number,
		customerReceipt?: string,
		savedCardResult?: OpenEdgePaymentSaveResult,
	) {
		request.paymentTransactionId = paymentTransactionId;
		let comment = request.comment || '';
		if (this.paymentComponentService.isInsurancePayer()) {
			comment += ` Card Transaction ID: ${transactionIdentifier}`;
		} else {
			request.referenceNumber = transactionIdentifier;
		}
		if (!_isEmpty(cardLast4)) {
			comment += ` Card Last 4: ${cardLast4}`;
		}
		request.comment = comment.trim() || null;

		if (EnumUtil.equalsOneOf(creditCardType, ...this.paymentComponentService.creditCardTypes)) {
			// use given card type if available in practice preferences
			request.creditCardType = creditCardType;
		} else if (EnumUtil.equalsOneOf(CreditCardType.OTHER, ...this.paymentComponentService.creditCardTypes)) {
			// use other if available and given card type is not available
			request.creditCardType = CreditCardType.OTHER;
		} else {
			// both given and other card type are not available
			request.creditCardType = null;
		}

		this.saveOrApplyPaymentGroupRequest(true, request, printReceipt, customerReceipt, savedCardResult);
	}

	setHasPaymentProcessor(paymentGroupId: number) {
		if (_isNil(paymentGroupId)) {
			this.hasPaymentProcessor = false;
		} else {
			this.accountingService.findPaymentProcessorForPaymentGroup(paymentGroupId).subscribe((response) => {
				this.hasPaymentProcessor = !_isNil(EnumUtil.findEnumByValue(response?.paymentTransactionProcessor?.value, PaymentTransactionProcessor));
			});
		}
	}

	launchEditPaymentModal() {
		this.typeSafeModalManagerService.open(EditPaymentModalComponent, {
			paymentGroupId: this.paymentComponentService.paymentGroup?.paymentGroupId,
			paymentMethodType: this.paymentComponentService.paymentGroup?.paymentMethodType,
			referenceNumber: this.paymentComponentService.paymentGroup?.referenceNumber,
			paymentDate: this.paymentComponentService.paymentGroup?.paymentDate,
			paymentMethodCreditCardType: this.paymentComponentService.paymentGroup?.creditCardType,
		})
			.onClose.subscribe((result) => {
				if (!_isNil(result)) {
					this.setPaymentGroupDetails(result);
					this.paymentService.refreshPayments();
				}
			});
	}

	setPaymentGroupDetails(result: EditPaymentModalData) {
		this.paymentComponentService.paymentGroup.paymentMethodType = result.paymentMethodType;
		this.paymentComponentService.paymentGroup.referenceNumber = result.referenceNumber;
		this.paymentComponentService.paymentGroup.paymentDate = result.paymentDate;
		this.paymentComponentService.paymentGroup.creditCardType = result.paymentMethodCreditCardType;
	}
}
